import React, { useState } from 'react'
import { PhoneCall, Download, Copy, WhatsappLogo as WP } from 'phosphor-react'
import html2canvas from 'html2canvas'
import logo from './logo.png'
import logos from './logos.svg'
import { saveAs } from 'file-saver';
import ClipboardJS from 'clipboard';

// Importa a biblioteca uuid
const uuid = require('uuid');

// Gera um UUID
const uniqueId = uuid.v4();

export function Signature({
  firstname,
  position,
  phone,
  wp,
  grupoafr,
  thegrace
}) {

  React.useEffect(() => {
    const clipboard = new ClipboardJS('.copy-button');

    return () => {
      clipboard.destroy();
    };
  }, []);

  const [signatureHTML, setSignatureHTML] = useState('')

  const handleDownloadHTML = ({ firstname, position, phone, wp }) => {
    const emailSignatureHTML = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Assinatura</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
          
    </head>
    <style>
      body {
          font-family: Figtree, Arial, sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          margin: 0;
          text-align: left;
          color: #525f7f;
          background-color: #fff;
      }
      </style>
    <body>
    <table style="padding: 10px;" width="400" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <td width="400" style="color: #172D4B; font-family: Figtree; font-size: 24px; font-weight: 700; line-height: normal;" >${firstname}</td>
      </tr> 
      <tr>
        <td style="margin-top: 0; color: #B2B3B0; font-family: Figtree; font-size: 16px; font-style: italic; font-weight: 400; line-height: normal;">${position}</td>
      </tr>
      <tr>
        <td valign="left" style="font-size: 12px; line-height: 16px; color:#461E40;  display: flex; align-items: center; gap: 5px; margin-top: 15px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M7.02412 1.25C3.73166 1.25 1.04824 3.93342 1.04824 7.22588C1.04824 8.28116 1.32563 9.30628 1.84422 10.2108L1 13.3103L4.16583 12.4781C5.0402 12.9545 6.02311 13.2078 7.02412 13.2078C10.3166 13.2078 13 10.5244 13 7.23191C13 5.63392 12.3789 4.13241 11.2513 3.00477C10.1236 1.87111 8.62211 1.25 7.02412 1.25ZM7.03015 2.25704C8.35678 2.25704 9.59899 2.77563 10.5397 3.71633C11.4744 4.65704 11.993 5.90528 11.993 7.23191C11.993 9.9696 9.76181 12.1947 7.02412 12.1947C6.13166 12.1947 5.25729 11.9595 4.49749 11.5013L4.31658 11.3987L2.43518 11.8932L2.93568 10.0601L2.81508 9.86709C2.3206 9.0892 2.05528 8.16658 2.05528 7.22588C2.06131 4.48819 4.28643 2.25704 7.03015 2.25704ZM4.90754 4.46407C4.81105 4.46407 4.64824 4.50025 4.50955 4.651C4.37688 4.80176 3.98492 5.1696 3.98492 5.89925C3.98492 6.63492 4.52161 7.34045 4.58794 7.44297C4.67236 7.54548 5.64925 9.05301 7.15075 9.69221C7.50653 9.85503 7.78392 9.94548 8.001 10.0118C8.35678 10.1264 8.68241 10.1083 8.94171 10.0721C9.23115 10.0299 9.82211 9.7103 9.94874 9.36055C10.0754 9.0108 10.0754 8.71533 10.0392 8.64899C9.99698 8.58869 9.9005 8.55251 9.74975 8.48618C9.59899 8.40176 8.86332 8.03995 8.73065 7.99171C8.59196 7.94347 8.50754 7.91935 8.39296 8.06407C8.29648 8.21482 8.00703 8.55251 7.92261 8.64899C7.83216 8.75151 7.74774 8.76357 7.60301 8.69121C7.44623 8.61281 6.96382 8.45603 6.39698 7.9495C5.95075 7.55151 5.65528 7.06307 5.56482 6.91231C5.49246 6.76759 5.55879 6.67714 5.63116 6.6108C5.69749 6.54447 5.79397 6.43593 5.85427 6.34548C5.93266 6.26105 5.95678 6.19472 6.00502 6.09824C6.05327 5.99573 6.02914 5.91131 5.99296 5.83894C5.95678 5.77261 5.65528 5.02487 5.52864 4.7294C5.40804 4.43995 5.28744 4.47613 5.19095 4.4701C5.10653 4.4701 5.01005 4.46407 4.90754 4.46407Z" fill="#172D4B"/>
          </svg>
          ${wp}

          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path d="M13.7361 10.8611C12.9694 10.8611 12.1389 10.7333 11.4361 10.4778H11.2444C11.0528 10.4778 10.925 10.5417 10.7972 10.6694L9.39167 12.075C7.60278 11.1167 6.06944 9.64722 5.175 7.85833L6.58056 6.45278C6.77222 6.26111 6.83611 6.00556 6.70833 5.81389C6.51667 5.11111 6.38889 4.28056 6.38889 3.51389C6.38889 3.19444 6.06944 2.875 5.75 2.875H3.51389C3.19444 2.875 2.875 3.19444 2.875 3.51389C2.875 9.51944 7.73056 14.375 13.7361 14.375C14.0556 14.375 14.375 14.0556 14.375 13.7361V11.5C14.375 11.1806 14.0556 10.8611 13.7361 10.8611ZM4.15278 4.15278H5.11111C5.175 4.72778 5.30278 5.30278 5.43056 5.81389L4.66389 6.58056C4.40833 5.81389 4.21667 4.98333 4.15278 4.15278ZM13.0972 13.0972C12.2667 13.0333 11.4361 12.8417 10.6694 12.5861L11.4361 11.8194C11.9472 11.9472 12.5222 12.075 13.0972 12.075V13.0972ZM10.5417 8.625H11.8194C11.8194 7.77778 11.4829 6.96526 10.8838 6.36619C10.2847 5.76711 9.47222 5.43056 8.625 5.43056V6.70833C9.13333 6.70833 9.62084 6.91027 9.98029 7.26971C10.3397 7.62916 10.5417 8.11667 10.5417 8.625ZM13.0972 8.625H14.375C14.375 5.43056 11.8003 2.875 8.625 2.875V4.15278C11.0911 4.15278 13.0972 6.1525 13.0972 8.625Z" fill="#172D4B"/>
          </svg>
          ${phone}
          
        
        </td>
      </tr>
      <tr>
        <td>
          <hr style="border: none; margin-top: 20px; border-top: 1px solid #E6E6E5; text-decoration: none; color: #172D4B; font-size: 9px; font-weight: 400; line-height: normal; display: flex; align-items: center; gap: 5px; padding-top: 10px;"/>
          <img width="300px" src="${addHTTPToURL(imageUrl)}"/>
        </td>
      </tr>
    </table>

    <div style="line-height:30%;"></div>
    </body>

    </html>

      
    `;
      
    const blob = new Blob([emailSignatureHTML], { type: 'text/html' });
    setSignatureHTML(emailSignatureHTML);
    saveAs(blob, 'email_signature.html');
  };

  const addHTTPToURL = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'https://mail-signature-the-grace.vercel.app' + url;
    }
    return url;
  };

  const handleCopy = () =>{
    const table = document.getElementById('assign');
    const tableHTML = table.outerHTML;
    const tempInput = document.createElement('textarea');
    tempInput.value = tableHTML.replace(/><(?!\/)/g, '>\n<');
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    alert('Tabela copiada para a área de transferência!');
  
 
  }

  const handleDownload = () => {
    const table = document.getElementById('assign');
    const images = table.getElementsByTagName('img');
    Array.from(images).forEach((img) => {
      img.setAttribute('crossorigin', 'anonymous');
    });

    html2canvas(table, {
      scrollX: 0,
      scrollY: -window.scrollY, 
    }).then((canvas) => {
      const imageUri = canvas.toDataURL('image/png');
      const fileName = `signature_${uniqueId}.png`;
      const a = document.createElement('a');
      a.href = imageUri;
      a.download = fileName;
      a.click();
    });
  };
  

  const imageUrl = 'https://mail-signature-the-grace.vercel.app/' + logos
  

  return (
    <div style={{ overflow: 'hidden', textAlign: 'left', 
                  fontFamily: 'Figtree', backgroundColor: 'white', 
                  borderRadius: '.25rem', padding: '1.5rem 0 1.5rem 0'}}>
      <div>
        <table id="assign">
          <tbody>
            <tr>
              
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          minWidth: '380px',
                          paddingLeft: '10px',
                          color: '#172D4B',
                          fontFamily: 'Figtree',
                          fontSize: '1.5rem',
                          fontWeight: '700',
                          lineHeight: 'normal'
                        }}
                      >
                        {`${firstname}`}
                        <div
                          style={{
                            marginTop: '0',
                            color: '#B2B3B0',
                            fontFamily: 'Figtree',
                            fontSize: '1rem',
                            fontStyle: 'italic',
                            fontWeight: '400',
                            lineHeight: 'normal'
                          }}
                        >
                          <i>{position}</i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '5px' }}>
                        <table>
                          <tbody>
  
                              <tr>    
                                <td
                                  style={{
                                    textDecoration: 'none',
                                    color: '#172D4B',
                                    fontFamily: 'Figtree',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    padding: '1rem 0 0 0'
                                  }}
                                >  
                                  {wp && (
                                    <>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                        <path d="M7.02412 1.25C3.73166 1.25 1.04824 3.93342 1.04824 7.22588C1.04824 8.28116 1.32563 9.30628 1.84422 10.2108L1 13.3103L4.16583 12.4781C5.0402 12.9545 6.02311 13.2078 7.02412 13.2078C10.3166 13.2078 13 10.5244 13 7.23191C13 5.63392 12.3789 4.13241 11.2513 3.00477C10.1236 1.87111 8.62211 1.25 7.02412 1.25ZM7.03015 2.25704C8.35678 2.25704 9.59899 2.77563 10.5397 3.71633C11.4744 4.65704 11.993 5.90528 11.993 7.23191C11.993 9.9696 9.76181 12.1947 7.02412 12.1947C6.13166 12.1947 5.25729 11.9595 4.49749 11.5013L4.31658 11.3987L2.43518 11.8932L2.93568 10.0601L2.81508 9.86709C2.3206 9.0892 2.05528 8.16658 2.05528 7.22588C2.06131 4.48819 4.28643 2.25704 7.03015 2.25704ZM4.90754 4.46407C4.81105 4.46407 4.64824 4.50025 4.50955 4.651C4.37688 4.80176 3.98492 5.1696 3.98492 5.89925C3.98492 6.63492 4.52161 7.34045 4.58794 7.44297C4.67236 7.54548 5.64925 9.05301 7.15075 9.69221C7.50653 9.85503 7.78392 9.94548 8.001 10.0118C8.35678 10.1264 8.68241 10.1083 8.94171 10.0721C9.23115 10.0299 9.82211 9.7103 9.94874 9.36055C10.0754 9.0108 10.0754 8.71533 10.0392 8.64899C9.99698 8.58869 9.9005 8.55251 9.74975 8.48618C9.59899 8.40176 8.86332 8.03995 8.73065 7.99171C8.59196 7.94347 8.50754 7.91935 8.39296 8.06407C8.29648 8.21482 8.00703 8.55251 7.92261 8.64899C7.83216 8.75151 7.74774 8.76357 7.60301 8.69121C7.44623 8.61281 6.96382 8.45603 6.39698 7.9495C5.95075 7.55151 5.65528 7.06307 5.56482 6.91231C5.49246 6.76759 5.55879 6.67714 5.63116 6.6108C5.69749 6.54447 5.79397 6.43593 5.85427 6.34548C5.93266 6.26105 5.95678 6.19472 6.00502 6.09824C6.05327 5.99573 6.02914 5.91131 5.99296 5.83894C5.95678 5.77261 5.65528 5.02487 5.52864 4.7294C5.40804 4.43995 5.28744 4.47613 5.19095 4.4701C5.10653 4.4701 5.01005 4.46407 4.90754 4.46407Z" fill="#172D4B"/>
                                      </svg>
                                      {wp}
                                    </>
                                  )}

                                  {phone && (
                                  <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                      <path d="M13.7361 10.8611C12.9694 10.8611 12.1389 10.7333 11.4361 10.4778H11.2444C11.0528 10.4778 10.925 10.5417 10.7972 10.6694L9.39167 12.075C7.60278 11.1167 6.06944 9.64722 5.175 7.85833L6.58056 6.45278C6.77222 6.26111 6.83611 6.00556 6.70833 5.81389C6.51667 5.11111 6.38889 4.28056 6.38889 3.51389C6.38889 3.19444 6.06944 2.875 5.75 2.875H3.51389C3.19444 2.875 2.875 3.19444 2.875 3.51389C2.875 9.51944 7.73056 14.375 13.7361 14.375C14.0556 14.375 14.375 14.0556 14.375 13.7361V11.5C14.375 11.1806 14.0556 10.8611 13.7361 10.8611ZM4.15278 4.15278H5.11111C5.175 4.72778 5.30278 5.30278 5.43056 5.81389L4.66389 6.58056C4.40833 5.81389 4.21667 4.98333 4.15278 4.15278ZM13.0972 13.0972C12.2667 13.0333 11.4361 12.8417 10.6694 12.5861L11.4361 11.8194C11.9472 11.9472 12.5222 12.075 13.0972 12.075V13.0972ZM10.5417 8.625H11.8194C11.8194 7.77778 11.4829 6.96526 10.8838 6.36619C10.2847 5.76711 9.47222 5.43056 8.625 5.43056V6.70833C9.13333 6.70833 9.62084 6.91027 9.98029 7.26971C10.3397 7.62916 10.5417 8.11667 10.5417 8.625ZM13.0972 8.625H14.375C14.375 5.43056 11.8003 2.875 8.625 2.875V4.15278C11.0911 4.15278 13.0972 6.1525 13.0972 8.625Z" fill="#172D4B"/>
                                    </svg>
                                    {phone}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{
                                    textDecoration: 'none',
                                    color: '#172D4B',
                                    fontFamily: 'Figtree',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    padding: '1rem 0 1rem 0'
                                  }}>
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.23074 6.875L11.4152 10.2313L10.838 10.3438L10.4183 10.4187C10.0051 10.5 9.76895 10.9375 9.95259 11.2937L10.1297 11.6562L11.0217 13.4937L10.0903 13.9062L9.19835 12.075L9.02782 11.7125C8.85074 11.3438 8.35884 11.2312 8.0309 11.4875L7.69641 11.7375L7.23074 12.0938V6.875ZM6.7454 5.43125C6.6132 5.43125 6.48642 5.48129 6.39294 5.57037C6.29946 5.65945 6.24694 5.78027 6.24694 5.90625V13.0625C6.24694 13.325 6.46994 13.5375 6.7454 13.5375C6.87002 13.5375 6.97496 13.5 7.06022 13.4375L8.31293 12.4688L9.40167 14.7312C9.48693 14.9 9.66401 15 9.85421 15C9.92636 15 9.99851 15 10.0707 14.95L11.8808 14.15C12.1301 14.0375 12.2481 13.75 12.117 13.5187L11.0217 11.25L12.6023 10.9688C12.7072 10.9375 12.8056 10.8938 12.8843 10.8063C13.0614 10.6063 13.0352 10.3125 12.8056 10.1312L7.07334 5.5375L7.06678 5.54375C6.98151 5.475 6.87002 5.43125 6.7454 5.43125ZM9.52628 6.25V5H12.8056V6.25H9.52628ZM8.75892 2.975L10.615 1.20625L11.5398 2.0875L9.68369 3.85625L8.75892 2.975ZM6.24694 0H7.55868V3.125H6.24694V0ZM2.26582 9.1625L4.12193 7.39375L5.0467 8.275L3.1906 10.0437L2.26582 9.1625ZM2.26582 2.0875L3.1906 1.20625L5.0467 2.975L4.12193 3.85625L2.26582 2.0875ZM4.27934 6.25H1V5H4.27934V6.25Z" fill="#172D4B"/>
                                  </svg>
                                    {grupoafr} | {thegrace}
                                </td>
                              </tr>
                            

                            <tr>
                              <td style={{
                                borderTop: '1px solid #E6E6E5',
                                textDecoration: 'none',
                                color: '#172D4B',
                                fontFamily: 'Figtree',
                                fontSize: '0.875rem',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                padding: '0.6rem 0 0 0' 
                         
                              }}>
                                <img src={logos} width="400" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

          </tbody>
        </table>

        <button onClick={handleDownload} style={{ marginTop: '10px', marginLeft: '5px', backgroundColor: '#3261AB', border: 'none', padding: '10px', color: '#fff', borderRadius: '8px' }}>
          <Download size={16} /> Download da assinatura
        </button>

      </div>
     
    </div>
    
  )
}
